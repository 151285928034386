<template>
  <div class="maintenance-start" v-if="!isProcessing">
    <div v-if="!isMaintenance">
      <maintenance-form @edit="updateMemo" />
      <v-btn class="maintenance-start__btn" :disabled="!isFilled" depressed @click="open()">メンテナンスの開始</v-btn>
      <confirm-dialog ref="confirm" title="メンテナンスの開始" msg="メンテナンスを開始すると、ユーザーがアプリにアクセスできなくなります。" @do-action="startMaintenance()" />
    </div>
    <div v-else>
      <div class="maintenance-start__warning">現在メンテナンス中です。</div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import MaintenanceForm from '@/components/maintenance/form.vue'
export default {
  name: 'maintenance_start',
  components: { ConfirmDialog, MaintenanceForm },
  data () {
    return {
      // メンテナンス理由
      memo: ''
    }
  },
  async mounted () {
    if (!this.maintenance) await this.$store.dispatch('maintenances/getMaintenance')
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object} メンテナンス情報
     */
    maintenance () {
      return this.$store.getters['maintenances/maintenance']
    },
    /**
     * @return {Boolean} メンテナンス中かどうか
     */
    isMaintenance () {
      return this.maintenance && !this.maintenance.endAt
    },
    /**
     * @return {Boolean} メンテナンス理由が記載済みかどうか
     */
    isFilled () {
      return this.memo.replace(/\s+/g, '').length > 0
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ダイアログを開く
     */
    open () {
      this.$refs.confirm.open()
    },
    /**
     * メンテナンスを開始する
     */
    async startMaintenance () {
      this.$store.commit('setSubmitting', true)
      // メンテナンスモードを開始
      await this.$store.dispatch('maintenances/createMaintenance', { createdAt: new Date(), endAt: null, memo: this.memo })
      // Stripeの定期支払い回収を停止
      await this.$store.dispatch('functions/switchSubscriptionCollections', true)
      // メンテナンス用のrulesに変更してDBアクセスを停止
      await this.$store.dispatch('functions/setMaintenanceRules', true)
      // ホームへ遷移
      this.$store.commit('setTelop', { show: true, msg: 'メンテナンスを開始しました', type: 'success' })
      this.$router.push({ name: 'home' })
    },
    /**
     * メンテナンス理由のテキストデータを更新
     * @param {String} input メンテナンス理由の文言
     */
    updateMemo (input) {
      this.memo = input
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.maintenance-start {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__btn {
    display: block;
    margin: 40px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
  &__warning {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
